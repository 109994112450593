
import { registerUser, userLogin } from "../api/auth";
import { EventTypes } from "../constants/event-type";
import { loadUserConfig } from "./user";
import Cookies from "universal-cookie";

const TOGGLE_LOGIN_VISIBILITY = "TOGGLE_LOGIN_VISIBILITY";
const TOGGLE_2FA_VISIBILITY = "TOGGLE_2FA_VISIBILITY";
const TOGGLE_REGISTER_VISIBILITY = "TOGGLE_REGISTER_VISIBILITY";
const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
const USER_CONFIG = "USER_CONFIG";
const USER_LOGIN_FAILED = "USER_LOGIN_FAILED";
const USER_REGISTER_FAILED = "USER_REGISTER_FAILED";

// Action Creator
const toggleLoginVisibility = (currentVisibility) => {
    return {
        type: TOGGLE_LOGIN_VISIBILITY,
        isLoginVisible: !currentVisibility
    }
};

const toggle2FAVisibility = (currentVisibility) => {
    return {
        type: TOGGLE_2FA_VISIBILITY,
        is2FAVisible: false,
    }
};

const toggleRegisterVisibility = (currentVisibility) => {
    return {
        type: TOGGLE_REGISTER_VISIBILITY,
        isRegisterVisible: !currentVisibility,
    }
};

export const authVisibilty = (state, currentState) => (dispatch) => {
    if (state === EventTypes.LOGIN) {
        dispatch(toggleLoginVisibility(currentState));
    } else if (state === EventTypes.REGISTER) {
        dispatch(toggleRegisterVisibility(currentState));
    } else if (state === EventTypes.TWO_FACTOR_AUTH) {
        dispatch(toggle2FAVisibility(currentState));
    }
    return null;
}

export const userAccountLoginSuccess = (response) => {
    return {
        type: USER_LOGIN_SUCCESS,
        accessToken: response,
        logIn: true
    }
};

const userRegistrationSuccess = (response) => {
    return {
        type: USER_REGISTER_SUCCESS,
        accessToken: response,
        logIn: true
    }
};

const userAccountLoginFailed = (response) => {
    return {
        type: USER_LOGIN_FAILED,
        logIn: false,
        error: response.message
    }
};

const userRegistrationFailed = (response) => {
    return {
        type: USER_REGISTER_FAILED,
        logIn: false,
        error: response.message
    }
};

const userLogoutSuccess = () => {
    return {
        type: USER_LOGOUT_SUCCESS,
        logIn: false
    }
};

export const userAccountLogin = (user) => (dispatch) => {
    console.log("User ", user);
    let cookie = new Cookies();
    userLogin(user).then((res) => {
        console.log("login res ", res);
        cookie.set('coreX_access_token',res.tokens.access_token, { path: '/' })
        cookie.set('coreX_refresh_token',res.tokens.refresh_token, { path: '/' })
        localStorage.setItem('coreX',res.user_id);
        dispatch(userAccountLoginSuccess(res));
        dispatch(loadUserConfig(res.user_id));
    }).catch((err)=>{
        console.log("error", err);
        dispatch(userAccountLoginFailed({message:"Login Failed. Invalid email/ password"}));
    })
}

export const userAccountRegister = (user) => (dispatch) => {
    console.log("User Registration", user);
    let cookie = new Cookies();
    registerUser(user).then((res) => {
        console.log("login res ", res);
        cookie.set('coreX_access_token',res.tokens.access_token, { path: '/' })
        cookie.set('coreX_refresh_token',res.tokens.refresh_token, { path: '/' })
        localStorage.setItem('coreX',res.user_id);
        dispatch(userRegistrationSuccess(res))
        dispatch(loadUserConfig(res.user_id));
    }).catch((err)=>{
        dispatch(userRegistrationFailed({message:"Registration Failed"}));
    })
}

export const userAccountLogout = (user) => (dispatch) => {
    console.log("User logout", user);
    let cookie = new Cookies();
    cookie.remove('coreX_access_token', { path: '/' });
    cookie.remove('coreX_refresh_token', { path: '/' });
    localStorage.removeItem('coreX')
    dispatch(userLogoutSuccess());
}
