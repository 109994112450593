import user1 from "../assets/profile-pictures/user1.jpg";
import user2 from "../assets/profile-pictures/user2.jpg";
import user3 from "../assets/profile-pictures/user3.jpg";
import user4 from "../assets/profile-pictures/user4.jpg";
import user5 from "../assets/profile-pictures/user5.jpg";
import user6 from "../assets/profile-pictures/user6.jpg";

export const baseUrl = "https://tradestox.in";

export const navItems = [
  { label: "Market", href: "market" },
  { label: "Profile", href: "profile" }
];

export const overViewItems = [
  {label:"Open", key:"open"},
  {label:"Prev Close", key:"close"},
  {label:"Today's Low", key:"low"},
  {label:"Today's High", key:"high"},
  {label:"52-Week Low", key:"-"},
  {label:"52-Week High", key:"-"},
  {label:"Last Traded Price", key:"price"},
  {label:"Last Traded Time", key:"lastTradedTime"},
  {label:"Volume", key:"volume"},
]

export const defaultStock = "NSE_INDEX|Nifty Auto";

export const redItems = [
  "Today's Low", "52-Week Low"
]

export const greenItems = [
  "Today's High", "52-Week High"
]

export const normalItems = [
  "Volume", "Last Traded Time", "Last Traded Price", "Prev Close", "Open"
]

export const depthItems = [
  "Order", "Qty", "Bid", "Ask","Qty", "Bid" 
]

export const depthValueItems = [
  {value: ["1", "50", "999.00", "1","40", "992.22"]},
  {value: ["2", "50", "999.00", "2","40", "992.22"]},
  {value: ["3", "50", "999.00", "3","40", "992.22"]}
]

export const redDepthItems = [
   "Ask"
]

export const greenDepthItems = [
  "Order"
]

export const normalDepthItems = [
  "Qty", "Bid"
]

export const stocks = [
  {label: "TATA MOT", key: "TATA MOT"},
  {label: "SBIN", key: "SBIN"},
  {label: "ADANI PORT", key: "ADANI PORT"},
]

export const topGainers = [
  { label: "TATA MOT", price: "2203.22", change: "20.12", percentage: "11.22" },
  { label: "ADANI PORT", price: "103.79", change: "3.25", percentage: "8.10" },
  { label: "REL MOT", price: "11.00", change: "10.09", percentage: "91.22" },
  { label: "IRCTC PORT", price: "90.23", change: "5.78", percentage: "0.45" }
];

export const topLosers = [
  { label: "SBIN", price: "503.22", change: "10.12", percentage: "23.24" },
  { label: "FEDERAL BANK", price: "10.79", change: "2.25", percentage: "55.10" },
  { label: "XWS MOT", price: "31.00", change: "6.09", percentage: "12.23" },
  { label: "SWTB PORT", price: "20.23", change: "13.78", percentage: "10.04" }
];

export const indices = [
  { label: "NIFTY 50", price: "12,503.22", change: "130.12", percentage: "3.40" },
  { label: "NIFTY BANK", price: "133,10.79", change: "322.25", percentage: "5.57" }
];

export const stockList = [
  {key:"NSE_INDEX|SMLCAP",value:"SMLCAP"},
  {key:"NSE_INDEX|NIFTY200 QUALTY30", value:"NIFTY200 QUALTY30"},
  {key:"NSE_INDEX|NIFTY LARGEMID250", value:"NIFTY LARGEMID250"},
  {key:"NSE_INDEX|NIFTY100 Qualty30", value:"NIFTY100 Qualty30"},
  {key:"NSE_INDEX|Nifty Media", value:"Nifty Media"},
  {key:"NSE_INDEX|Nifty Auto", value:"Nifty Auto"},,
  {key:"NSE_EQ|INE669E01016", value:"IDEA"}
]

export const testimonials = [
  {
    user: "John Doe",
    company: "Stellar Solutions",
    image: user1,
    text: "I am extremely satisfied with the services provided. The team was responsive, professional, and delivered results beyond my expectations.",
  },
  {
    user: "Jane Smith",
    company: "Blue Horizon Technologies",
    image: user2,
    text: "I couldn't be happier with the outcome of our project. The team's creativity and problem-solving skills were instrumental in bringing our vision to life",
  },
  {
    user: "David Johnson",
    company: "Quantum Innovations",
    image: user3,
    text: "Working with this company was a pleasure. Their attention to detail and commitment to excellence are commendable. I would highly recommend them to anyone looking for top-notch service.",
  },
  {
    user: "Ronee Brown",
    company: "Fusion Dynamics",
    image: user4,
    text: "Working with the team at XYZ Company was a game-changer for our project. Their attention to detail and innovative solutions helped us achieve our goals faster than we thought possible. We are grateful for their expertise and professionalism!",
  },
  {
    user: "Michael Wilson",
    company: "Visionary Creations",
    image: user5,
    text: "I am amazed by the level of professionalism and dedication shown by the team. They were able to exceed our expectations and deliver outstanding results.",
  },
  {
    user: "Emily Davis",
    company: "Synergy Systems",
    image: user6,
    text: "The team went above and beyond to ensure our project was a success. Their expertise and dedication are unmatched. I look forward to working with them again in the future.",
  },
];

export const features = [

];

export const checklistItems = [
  {
    title: "Code merge made easy",
    description:
      "Track the performance of your VR apps and gain insights into user behavior.",
  },
  {
    title: "Review code without worry",
    description:
      "Track the performance of your VR apps and gain insights into user behavior.",
  },
  {
    title: "AI Assistance to reduce time",
    description:
      "Track the performance of your VR apps and gain insights into user behavior.",
  },
  {
    title: "Share work in minutes",
    description:
      "Track the performance of your VR apps and gain insights into user behavior.",
  },
];

export const pricingOptions = [
  {
    title: "Free",
    price: "$0",
    features: [
      "Private board sharing",
      "5 Gb Storage",
      "Web Analytics",
      "Private Mode",
    ],
  },
  {
    title: "Pro",
    price: "$10",
    features: [
      "Private board sharing",
      "10 Gb Storage",
      "Web Analytics (Advance)",
      "Private Mode",
    ],
  },
  {
    title: "Enterprise",
    price: "$200",
    features: [
      "Private board sharing",
      "Unlimited Storage",
      "High Performance Network",
      "Private Mode",
    ],
  },
];

export const resourcesLinks = [
  { href: "#", text: "Getting Started" },
  { href: "#", text: "Documentation" },
  { href: "#", text: "Tutorials" },
  { href: "#", text: "API Reference" },
  { href: "#", text: "Community Forums" },
];

export const platformLinks = [
  { href: "#", text: "Features" },
  { href: "#", text: "Supported Devices" },
  { href: "#", text: "System Requirements" },
  { href: "#", text: "Downloads" },
  { href: "#", text: "Release Notes" },
];

export const communityLinks = [
  { href: "#", text: "Events" },
  { href: "#", text: "Meetups" },
  { href: "#", text: "Conferences" },
  { href: "#", text: "Hackathons" },
  { href: "#", text: "Jobs" },
];
