/* eslint-disable */
import React from "react";
import { authVisibilty, userAccountRegister } from "../../actions/auth";
import { connect, useDispatch, useSelector } from "react-redux";
import { EventTypes } from "../../constants/event-type";
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import LoaderComponent from "../LoaderComponent";

const createAccountForm = () => {

  const { register, formState: { errors }, trigger, getValues, clearErrors } = useForm();


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoginVisible = useSelector((state) => state.auth.isLoginVisible);
  const isRegisterVisible = useSelector((state) => state.auth.isRegisterVisible);
  const is2FAVisible = useSelector((state) => state.auth.is2FAVisible);

  const ValidateAndCreateAccount = async (event) => {
    event.preventDefault();
    const isValid = await trigger();
    const formData = getValues();

    if (isValid) {
        console.log('Form Submitted Successfully! Data:', formData);
        dispatch(userAccountRegister(formData));
        dispatch(authVisibilty(EventTypes.REGISTER, isRegisterVisible));
        dispatch(authVisibilty(EventTypes.TWO_FACTOR_AUTH, is2FAVisible));
        navigate("/");
    } else {
      console.log('Form is invalid! Current Data:', formData);
    }
  };

  const ToLogin = () => {
    dispatch(authVisibilty(EventTypes.REGISTER, isRegisterVisible));
    dispatch(authVisibilty(EventTypes.LOGIN, isLoginVisible));
  };

  const handleInputChange = (field) => {
    return (event) => {
      clearErrors(field); 
    };
  };

  return (
    <div>
      <div>
        <LoaderComponent />
      </div>
      <h1 class="text-xl h- md:text-4xl my-4 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
        Start Your Journey
      </h1>
      <form class="w-full">
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-first-name"
            >
              First Name
            </label>
            <input
              className={`custom-input ${errors.first_name ? 'custom-input-error' : ''} w-full`}
              id="grid-first-name"
              type="text"
              placeholder="John"
              {...register('firstName', { required: 'First Name is required.' })}
              onChange={handleInputChange('first_name')}
            />
             { errors.first_name && <p class="text-red-500 text-xs italic">{errors.first_name.message}</p> }
          </div>
          <div class="w-full md:w-1/2 px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-last-name"
            >
              Last Name
            </label>
            <input
              className={`custom-input ${errors.last_name ? 'custom-input-error' : ''} w-full`}
              id="grid-last-name"
              type="text"
              placeholder="Doe"
              {...register('lastName', { required: 'Last Name is required.' })}
              onChange={handleInputChange('last_name')}
            />
            { errors.last_name && <p class="text-red-500 text-xs italic">{errors.last_name.message}</p> }
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-phone"
            >
              Phone Number
            </label>
            <input
              className={`custom-input ${errors.phone ? 'custom-input-error' : ''} w-full`}
              id="grid-phone"
              maxLength={10}
              type="tel"
              placeholder="123-456-7890"
              {...register('phone', { 
                required: 'Phone number is required', 
                pattern: {
                  value: /^\d{10}$/,
                  message: 'Enter valid Phone number.'
                }
              })}
              onChange={handleInputChange('phone')} 
            />
            { errors.phone && <p class="text-red-500 text-xs italic">{errors.phone.message}</p> }
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-email"
            >
              Email
            </label>
            <input
              className={`custom-input ${errors.email ? 'custom-input-error' : ''} w-full`}
              id="grid-email"
              type="email"
              placeholder="Johndoe@example.com"
              {...register('email', { 
                required: 'Email is required.', 
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: 'Enter a valid email address.'
                }
              })}
              onChange={handleInputChange('email')} 
            />
              { errors.email && <p class="text-red-500 text-xs italic">{errors.email.message}</p> }
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
            >
              Password
            </label>
            <input
              className={`custom-input ${errors.password ? 'custom-input-error' : ''} w-full`}
              id="grid-password"
              type="password"
              placeholder="******************"
              {...register('password', { 
                required: 'Password is required.',
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters long.'
                },
                pattern: {
                  value: /^(?=.*[A-Z])(?=.*[\W_]).+$/,
                  message: 'Password must contain at least one uppercase letter and one special character.'
                }
              })}
              onChange={handleInputChange('password')} 
            />
               { errors.password && <p class="text-red-500 text-xs italic">{errors.password.message}</p> }
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-2">
          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-city"
            >
              City
            </label>
            <input
              className={`custom-input ${errors.city ? 'custom-input-error' : ''} w-full`}
              id="grid-city"
              type="text"
              placeholder="Chennai"
              {...register('city', { required: 'City is required.' })}
              onChange={handleInputChange('city')}
            />
              { errors.city && <p class="text-red-500 text-xs italic">{errors.city.message}</p> }
          </div>
          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-state"
            >
              State
            </label>
            <div class="relative">
              <select
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 focus:ring-2 focus:ring-violet-500"
                id="grid-state"
                {...register('state', { required: 'state is required.' })}
              >
                <option>Tamil nadu</option>
                <option>Puducherry</option>
                <option>Kerala</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-zip"
            >
              Zip
            </label>
            <input
              className={`custom-input ${errors.zip ? 'custom-input-error' : ''} w-full`}
              id="grid-zip"
              type="text"
              placeholder="60210"
              {...register('zip', { required: 'zip is required.' })}
              onChange={handleInputChange('zip')}
            />
            { errors.zip && <p class="text-red-500 text-xs italic">{errors.zip.message}</p> }
          </div>
        </div>
      </form>
      <div className="my-5">
        <button
          className="custom-button w-full"
          onClick={ValidateAndCreateAccount}
        >
          Create Account
        </button>
      </div>
      <div className="text-gray-400 my-2 text-center">
            <p className="cursor-pointer" onClick={ToLogin}> Already a Member? LoginIn Here </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log("state-create-account", state);
  return {
    auth: state.auth || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authVisibilty: (param) => dispatch(authVisibilty(param)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(createAccountForm);
