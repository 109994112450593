import Cookies from "universal-cookie";
import { baseUrl } from "../constants";

export const loadUser = async(id) =>{
    const cookie = new Cookies();
    let url = `${baseUrl}/v1/corex/getUser`;
    const res = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'jwt': cookie.get('coreX_access_token')
        },
        body: JSON.stringify({
            "user_id": id
        })
    });
    if (!res.ok) {
        const message = `Error ${res.status}`
        throw new Error(message);
    }
    let r = await res.json();
    return r;
}