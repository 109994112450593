import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { connect, useDispatch } from "react-redux";
import { userAccountLogout } from "../actions/auth";
import { useNavigate } from "react-router-dom";
import { loadUserConfig } from "../actions/user";
import { defaultStockUpdate } from "../actions/stock";

const Profile = (props) => {

    const [loggedIn, setLoggedIn] = useState('false');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userLogout = () => {
        setLoggedIn(true);
        dispatch(userAccountLogout());
        navigate("/");
    }

    useEffect(() => {
        dispatch(loadUserConfig(localStorage.getItem('coreX')))
        console.log("user", props);
    }, [])

    const updateStock = (stock) => {
        dispatch(defaultStockUpdate(stock));
        setTimeout(() => {
            navigate('/market');
        }, 500)
    }
    return (
        <div className="max-w-screen-md m-auto">
            <div className='max-w-7xl mx-auto pt-1 px-2 h-screen'>
                {!localStorage.getItem('coreX') ? <span className="flex items-center justify-center">Please login to view your profile</span> : <>
                    <div className="flex flex-col">
                        <div className="float-root w-full mt-2 bg-slate-200 p-2 rounded-md">
                            <div className="flex flex-col float-left w-1/2 p-1">
                                <p className="text-sm md:text-lg text-gray-800 tracking-wide">{props.user.user && props.user.user.data.firstName} {props.user.user && props.user.user.data.lastName}</p>
                                <span className="text-xs text-gray-500 tracking-wider">CoreX Points: -</span>
                                <span className="text-xs text-gray-500 tracking-wider">CoreX Rank: - </span>
                            </div>
                            <div className="flex flex-col float-right bg-blue-800 w-1/2 m-auto rounded-md p-3 items-center justify-center">
                                <span className="text-xs md:text-sm text-white">CoreX Coins</span>
                                <span className="text-xs md:text-sm text-white">{(props.user.userHoldings && props.user.userHoldings[0]) && props.user.userHoldings[0].virtual_wallet.tournament_coins}</span>
                            </div>
                        </div>
                        <div className="flex w-full mt-2 bg-slate-200 p-2 rounded-md space-x-2">
                            <div className="flex flex-col float-right bg-slate-100 w-1/2 m-auto rounded-md p-3 items-center justify-center">
                                <span className="text-xs md:text-sm ">Profit/ Loss</span>
                                <span className="text-xs md:text-sm "><span className={`${props.user.plData && props.user.plData.total_current_standing < props.user.plData.total_holdings_amount ? "text-red-600" : "text-green-600"}`} >{props.user.plData ? props.user.plData.total_current_standing : "-"}</span> / {props.user.plData ? props.user.plData.total_holdings_amount : "-"}</span>
                            </div>
                            <div className="flex flex-col float-right bg-slate-100 w-1/2 m-auto rounded-md p-3 items-center justify-center">
                                <span className="text-xs md:text-sm ">Available Balance</span>
                                <span className="text-xs md:text-sm ">{(props.user.userHoldings && props.user.userHoldings[0]) && props.user.userHoldings[0].virtual_wallet.available_amount}</span>
                            </div>
                        </div>

                        <div className="float-root w-full bg-slate-200 rounded-md mt-2 p-2 items-center justify-center h-96 overflow-y-auto">
                            <ul class=" divide-y divide-gray-200 dark:divide-gray-700 items-center justify-center">
                                {(props.user.plData && props.user.plData.holdingsData) ? props.user.plData.holdingsData.map((item, index) =>
                                    <li class="pb-3 sm:pb-4" onClick={()=>{updateStock(item.id)}}>
                                        <div class="flex items-center space-x-4 rtl:space-x-reverse p-3 bg-slate-100 rounded-md shadow-md">
                                            <div class="flex-1 min-w-0">
                                                <p class="text-sm font-medium text-gray-900 truncate ">
                                                    {item && item.symbol}
                                                </p>
                                                <p class="text-xs md:text-base text-gray-500 truncate ">
                                                    Quantity: {item && item.quantity} | <span className={`${item.change < 0 ? "text-red-600" : "text-green-600"}`}> P/L : {item && item.change} </span>
                                                </p>
                                            </div>
                                            <div class="flex flex-col items-center text-sm md:text-base font-normal text-gray-900 ">
                                                <span className={`${item.change < 0 ? "text-red-600" : "text-green-600"}`}> CP: {item && item.total_amount} </span>
                                                <span> BP: {item && item.total_amount} </span>
                                            </div>
                                        </div>
                                    </li>
                                ) : <li>
                                    <div class="flex items-center justify-center space-x-4 rtl:space-x-reverse p-3 bg-slate-100 rounded-md shadow-md">
                                            <div class="flex min-w-0 items-center justify-center">
                                                <p class="text-sm font-medium text-gray-900 truncate ">
                                                    Kickoff your virutal trade journey
                                                </p>
                                            </div>
                                        </div>
                                </li>}


                            </ul>
                        </div>
                        <div className="w-full bg-slate-200 mt-2 rounded-md p-5">
                            <p className="text-xl">Profile</p>
                            <div className="flex p-1 px-4 text-sm md:text-lg">
                                <p className=" w-1/3">Name</p>
                                <p className="">{props.user.user && props.user.user.data.firstName} {props.user.user && props.user.user.data.lastName}</p>
                            </div>
                            <div className="flex p-1 px-4 text-sm md:text-lg">
                                <p className=" w-1/3">Email</p>
                                <p className="">{props.user.user && props.user.user.email}</p>
                            </div>
                            <div className="flex px-4 p-1 text-sm md:text-lg">
                                <p className=" w-1/3">Phone</p>
                                <p className="">{props.user.user && props.user.user.data.phone}</p>
                            </div>
                        </div>
                    </div></>}
                {localStorage.getItem('coreX') ?
                    <div className="flex items-center justify-center mt-3 mb-3">
                        <button className="h-10 w-10/12 bg-slate-600 text-white rounded-md text-xl" onClick={() => { userLogout() }}>Log Out</button>
                    </div> : <div className="flex items-center justify-center mt-3 mb-3">
                        <button className="h-10 w-10/12 bg-slate-600 text-white rounded-md text-xl" onClick={() => { navigate("/auth"); }}>Log In</button>
                    </div>}
            </div>


            {/* <div className="sticky bottom-0 z-50 bg-gray-300 p-1 h-14 pt-2 flex justify-center space-x-5">
                <button className="h-10 w-10/12 bg-slate-600 text-white rounded-md text-xl" onClick={() => { userLogout() }}>Log Out</button>

            </div> */}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user.user || {}
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadUserConfig: param => dispatch(loadUserConfig(param))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);