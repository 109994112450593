import React from "react";

const Footer = () => {
    return (
        <footer class="grid grid-cols-4 sticky top-0 z-50 py-3 backdrop-blur-lg border-neutral-700/80 border border-t-orange-700 border-l-0 border-r-0 border-b-0">
            <span class="col-start-1 col-end-3 text-sm text-gray-500 sm:text-center justify-start dark:text-gray-400">© 2024 <a href="#" class="hover:underline">CoreX™</a>. All Rights Reserved.
            </span>
            <ul class="col-start-4 col-end-4 flex flex-wrap justify-items-end items-end mt-3 text-sm font-sm text-gray-500 dark:text-gray-400 sm:mt-0">
                <li>
                    <a href="#" class="hover:underline me-4 md:me-6">Market</a>
                </li>
                <li>
                    <a href="#" class="hover:underline me-4 md:me-6">Profile</a>
                </li>
                <li>
                    <a href="#" class="hover:underline me-4 md:me-6">Contact</a>
                </li>
            </ul>
        </footer>
    )
}

export default Footer;