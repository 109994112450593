import React, { useContext, useEffect, useState } from "react";
import { mockHistoricalData } from "../constants/mock";
import { StockChartComponent, StockChartSeriesCollectionDirective, StockChartSeriesDirective, Inject, DateTime, DateTimeCategory, Tooltip, RangeTooltip, Crosshair, LineSeries, SplineSeries, CandleSeries, HiloOpenCloseSeries, HiloSeries, RangeAreaSeries, Trendlines, Zoom } from '@syncfusion/ej2-react-charts';
import { EmaIndicator, RsiIndicator, BollingerBands, TmaIndicator, MomentumIndicator, SmaIndicator, AtrIndicator, AccumulationDistributionIndicator, MacdIndicator, StochasticIndicator, Export } from '@syncfusion/ej2-react-charts';
import { chartConfig } from "../constants/config";
import { chartData } from "../constants/mock";
import { connect } from "react-redux";

const Chart = (props) => {
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState("1W");

    const tooltipRender = (args) => {
        if (args.text.split('<br/>')[4]) {
            let target = parseInt(args.text.split('<br/>')[4].split('<b>')[1].split('</b>')[0]);
            let value = (target / 100000000).toFixed(1) + 'B';
            args.text = args.text.replace(args.text.split('<br/>')[4].split('<b>')[1].split('</b>')[0], value);
        }
    };

    const SAMPLE_CSS = `
    .control-fluid {
        padding: 0px !important;
    }
        .charts {
            align :center
        }`;

    const getFilters = () => {
        console.log(Object.keys(chartConfig))
        return <>
            {Object.keys(chartConfig).map((item) => {
                console.log("item", item);
                <li key={item}>
                    {item}
                </li>
            })}
        </>
    }

    useEffect(()=>{
        console.log(props.history);
    },[])

    const periodselector = [
        { text: '30M', interval: 35, intervalType: 'Minutes', selected: true },
        { text: '1H', interval: 1, intervalType: 'Hours' },
        { text: '4H', interval: 4, intervalType: 'Hours' },
        { text: '12H', interval: 12, intervalType: 'Hours' },
        { text: '1D', interval: 1, intervalType: 'Days' }
    ];

    const zoomsettings = {
        enableMouseWheelZooming: true, enablePinchZooming: true,
        enableSelectionZooming: true
    };

    return (
        
            <div className='control-pane'>
                <style>
                    
                </style>
                <div className='control-section'>
                    <StockChartComponent id='stockchartdefault' theme='FabricDark' periods={periodselector} zoomSettings={zoomsettings} primaryXAxis={{
                        valueType: 'DateTimeCategory',
                        majorGridLines: { width: 0 }, majorTickLines: { color: 'transparent' },
                        crosshairTooltip: { enable: true }
                    }} primaryYAxis={{
                        labelFormat: 'n0',
                        lineStyle: { width: 0 }, rangePadding: 'None',
                        majorTickLines: { width: 0 },
                        majorGridLines: { width: 0 },
                        crosshairTooltip: { enable: true }
                    }} chartArea={{ border: { width: 0 } }} tooltip={{ enable: true, shared: true }} tooltipRender={tooltipRender} crosshair={{ enable: true }}>
                        <Inject services={[DateTime, DateTimeCategory, Tooltip, RangeTooltip, Crosshair, LineSeries, SplineSeries, CandleSeries, HiloOpenCloseSeries, HiloSeries, RangeAreaSeries, Trendlines,
                            EmaIndicator, RsiIndicator, BollingerBands, TmaIndicator, MomentumIndicator, SmaIndicator, AtrIndicator, Export,
                            AccumulationDistributionIndicator, MacdIndicator, StochasticIndicator]} />
                        <StockChartSeriesCollectionDirective>
                            <StockChartSeriesDirective dataSource={props.history} xName='x' type='Candle' animation={{ enable: true }} enableSolidCandles={true} >
                            </StockChartSeriesDirective>
                        </StockChartSeriesCollectionDirective>
                    </StockChartComponent>
                </div>
            </div>
        
    )
}

const mapStateToProps = state => {
    return {
        history: state.stockDetails.history || []
    }
}


export default connect(mapStateToProps, null)(Chart);