import { useEffect, useState } from 'react';
import './App.css';
import Home from './components/home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Market from './components/Market';
import { applyMiddleware, createStore } from 'redux';
import corex from './reducers';
import { thunk } from 'redux-thunk';
import { Provider } from 'react-redux';
import Profile from './components/Profile';
import Auth from "./components/auth/auth";
import Navbar from './components/Navbar';
import MarketRevamp from './components/MarketRevamp';

function App(props) {

  const store = createStore(corex, applyMiddleware(thunk));

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/market' element={<Market />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/auth' element={<Auth />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
