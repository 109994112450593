import React from "react";
import { lb } from "../constants/mock";

const Leaderboard = () => {
    return (

        <div class="relative bg-slate-700 shadow-md rounded-lg p-3">
            <label className="text-base md:text-xl tracking-wide pb-2 text-white">LeaderBoard</label>
            <div class="relative overflow-y-auto shadow-md rounded-lg h-80">
                <table class="w-full text-sm text-left rtl:text-right text-blue-100 dark:text-blue-100">
                    <thead class="text-xs text-white uppercase bg-slate-700 -600 dark:text-white">
                        <tr>
                            <th scope="col" class="px-3 py-2">
                                Rank
                            </th>
                            <th scope="col" class="px-3 py-2">
                                User Name
                            </th>
                            <th scope="col" class="px-3 py-2">
                                Points
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            lb.map((item, index) =>
                                <tr class={`${index % 2 == 0 ? "bg-slate-200" : "bg-slate-300"} text-slate-900 border-b`}>
                                    <td class="px-3 py-2">
                                        {item.rank}
                                    </td>
                                    <td class="px-3 py-2">
                                        {item.name}
                                    </td>
                                    <td class="px-3 py-2">
                                        {item.points}
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Leaderboard;