import React from "react";
import { Link, redirect, useNavigate } from "react-router-dom";

const HeroSection =()=>{
    return(
        <div className="flex flex-col items-center mt-1 lg:mt-12">
            <h1 className="text-4xl sm:text-6xl lg:text-7xl text-center md:text-left tracking-wide">
                To Enhance Your Trade 
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
                    {" "}
                    Do Virtual Trade.
                </span>
            </h1>
            <p className="mt-10 text-lg text-center md:text-left text-neutral-500 max-w-4xl">
                Empower your trading capability and enhance your tarding statergies with powerful vitrual trading
                practice. Sharpen your strength and compitate with learners and boost your confidence.
                Happy Trading.
            </p>
            <button className="flex justify-center my-10 custom-button">
                <Link to="/market" >Start Your Trade</Link>
            </button>
        </div>
    )
}

export default HeroSection;