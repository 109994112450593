import { authVisibilty, userAccountLogin } from "../../actions/auth";
import { connect, useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import { EventTypes }  from "../../constants/event-type";
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";


const LoginForm =  (props) => {
  const { register, formState: { errors }, trigger, getValues, clearErrors } = useForm();

  const dispatch = useDispatch();
  const navigate= useNavigate();
  const [showError, setShowError] = useState(false);

  const isLoginVisible = useSelector((state) => state.auth.isLoginVisible);
  const isRegisterVisible = useSelector((state) => state.auth.isRegisterVisible);
  const is2FAVisible = useSelector((state) => state.auth.is2FAVisible);

  const validateAndLogin = async (event) => {
    event.preventDefault();
    const isValid = await trigger();
    const formData = getValues();

    if (isValid) {
        console.log('Form Submitted Successfully! Data:', formData);
        dispatch(userAccountLogin(formData));
        // dispatch(authVisibilty(EventTypes.LOGIN, isLoginVisible));
        // dispatch(authVisibilty(EventTypes.TWO_FACTOR_AUTH, is2FAVisible));
        //navigate("/");
    } else {
      console.log('Form is invalid! Current Data:', formData);
    }
  }

  const ToCreateAccountSection = () => {
    dispatch(authVisibilty(EventTypes.LOGIN, isLoginVisible));
    dispatch(authVisibilty(EventTypes.REGISTER, isRegisterVisible));
  }

  const handleInputChange = (field) => {
    return (event) => {
      clearErrors(field); 
    };
  };

  useEffect(()=>{
    if(props.auth.error){
      setShowError(true);
    }else{
      setShowError(false)
    }
  },[props.auth.error])

  useEffect(()=>{
    if(props.auth.status === 'success'){
      navigate('/')
    }
  },[props.auth.status])

  return (
    <div className="w-full md:w-3/4 mt-5 md:mt-0">
      <h1 className="text-xl md:text-4xl my-4 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
        Start Trading.
      </h1>
      <form className="w-full">
        <div className="flex flex-wrap  mb-6">
          <div className="w-full px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-email"
            >
              Email
            </label>
            <input
              className={`custom-input ${errors.email ? 'custom-input-error' : ''} w-full`}
              id="grid-email"
              type="email"
              placeholder="Johndoe@example.com"
              {...register('email', { 
                required: 'Email is required.', 
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: 'Enter a valid email address.'
                }
              })}
              onChange={handleInputChange('email')}
            />
            { errors.email && <p class="text-red-500 text-xs italic">{errors.email.message}</p> }
          </div>
        </div>
        <div className="flex flex-wrap  mb-6">
          <div className="w-full px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
            >
              Password
            </label>
            <input
              className={`custom-input ${errors.password ? 'custom-input-error' : ''} w-full`}
              id="grid-password"
              type="password"
              placeholder="******************"
              {...register('password', { required: 'Password is required.' })}
              onChange={handleInputChange('password')}
            />
          { errors.password && <p class="text-red-500 text-xs italic">{errors.password.message}</p> }
          </div>
        </div>
        <div className="my-10">
          {showError ? <span class='text-xs md:text-sm text-red-600 text-center justify-center mb-1 flex'>{props.auth.error}</span> : ""}
          <button className="custom-button w-full" 
          onClick={validateAndLogin}>
            Login
          </button>
          <div className="flex items-center my-2">
            <hr className="flex-1 border-t border-gray-300" />
            <span className="mx-4 text-gray-400">OR</span>
            <hr className="flex-1 border-t border-gray-300" />
          </div>
          <div className="text-gray-400 my-2 text-center">
            <p className="cursor-pointer" onClick={ToCreateAccountSection}> Not a Member? Register Here </p>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state => {
  console.log("state-login->>",state);
  return {
    auth: state.auth || {}
  }
}

const mapDispatchToProps = dispatch => {
  return {
    authVisibilty: param => dispatch(authVisibilty(param))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
