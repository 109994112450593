import React, { useContext, useEffect, useState } from "react";
import Chart from "./Chart";
import { overViewItems, redItems, greenItems, normalItems, depthItems, redDepthItems, greenDepthItems, normalDepthItems, depthValueItems, stocks } from "../constants";
import { buySingleStock, searchStock, sellSingleStock, stockHistory } from "../actions/stock";
import { connect, useDispatch } from "react-redux";
import { stockList as stockIntial, defaultStock } from "../constants";
import LiveCandlestickChart from "./LiveCandlestickChart";

const MarketRevamp = (props) => {

    const [showSearch, setSearch] = useState(false);
    const [showBuySell, setBuySell] = useState(false);
    const [quantity, setQuantity] = useState(0)
    const [buySellAction, setBuySellAction] = useState(true);
    const [results, setResults] = useState([]);
    const [stock, setStock] = useState('');
    const [stockList, setStockList] = useState(stockIntial);
    const dispatch = useDispatch()
    const changeStock = () => {
        setSearch(!showSearch);
    }

    const selectStock = (stock) => {
        console.log(stock);
        setStock(stock);
        setSearch(false);
        setResults(stockIntial)
        dispatch(searchStock(stock));
        dispatch(stockHistory(stock));
    }

    const handleSearch = (value) => {
        let input = value.target.value
        if (input && input.length > 2) {
            let st = stockList.filter((item) => item && item.value.includes(input));
            setStockList(st)
        } else if (input && input.length > 0) {
            setStockList(stockIntial)
        }
    }

    const handleBuyClick = (value, buy) => {
        setBuySell(value);
        setBuySellAction(buy);
    }

    const handleBuySell = (value) => {
        if (value)
            dispatch(buySingleStock(stock, quantity, ""));
        else
            dispatch(sellSingleStock(stock, quantity, ""));
    }

    const clearSearch = () => {
        setSearch(false);
        setStockList(stockIntial)
    }

    useEffect(() => {
        dispatch(searchStock(defaultStock));
        dispatch(stockHistory(defaultStock));
    }, []);

    return (
        <div>
            <div class={`fixed z-10 overflow-y-auto top-15 w-full left-0 ${showSearch ? "block" : "hidden"}`} id="modal">
                <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-gray-400 bg-opacity-50 h-screen">
                    <div class="w-full max-w-screen-xl mx-auto px-0 h-screen">
                        <div class="flex justify-center p-4 px-3 py-10">
                            <div class="w-full max-w-md">
                                <div class="bg-white shadow-md rounded-lg px-3 py-2 mb-4">
                                    <div class="block text-gray-700 text-lg font-semibold py-2 px-2">
                                        Stock List
                                    </div>
                                    <div class="flex items-center bg-gray-200 rounded-md">
                                        <input
                                            class="w-full rounded-md bg-gray-200 text-gray-400 text-sm leading-tight focus:outline-none py-2 px-2"
                                            id="search" type="text" placeholder="Search" onChange={handleSearch} />
                                    </div>
                                    <div class="py-3 text-sm">
                                        {
                                            stockList.map((item) =>
                                                <div class=" cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2 text-left" onClick={() => { selectStock(item.key) }}>
                                                    <span class="flex-grow font-medium px-2 hover:bg-blue-100" >{item.value}</span>
                                                </div>
                                            )
                                        }

                                    </div>
                                    <div class="block bg-gray-200 text-sm text-right py-2 px-3 -mx-3 -mb-2 rounded-b-lg">
                                        <button class="hover:text-gray-600 text-gray-500 font-bold py-2 px-4" onClick={() => { clearSearch() }}>
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class={`fixed z-10 overflow-y-auto top-15 w-full left-0 ${showBuySell ? "block" : "hidden"}`} id="modal">
                <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-gray-400 bg-opacity-50 h-screen">
                    <div class="w-full max-w-screen-xl mx-auto px-0 h-screen">
                        <div class="flex justify-center p-4 px-3 py-10">
                            <div class="w-full max-w-md">
                                <div class="bg-white md:w-3/4 shadow-md rounded-lg px-3 py-2 mb-4">
                                    <div class="block text-gray-500 text-sm md:text-lg font-semibold py-2 px-2">
                                        Enter the quantity
                                    </div>
                                    <div class="mb-2 items-center  rounded-md">
                                        <input
                                            class="w-full rounded-md bg-gray-200 text-gray-400 text-sm leading-tight focus:outline-none py-2 px-2"
                                            id="search" type="text" placeholder="0" onChange={(e) => { setQuantity(e.target.value) }} />
                                        {buySellAction ? <button className=" shadow-md  mt-3 h-7 md:h-10 w-16 bg-green-600 text-white rounded-2xl text-sm md:text-base" onClick={(() => { handleBuySell(true,) })}>Buy</button> : <button className=" shadow-md  mt-3 h-7 md:h-10 w-16 bg-red-600 text-white rounded-2xl text-sm md:text-base">Sell</button>}
                                    </div>
                                    <div class="block bg-gray-100 text-sm text-right py-1 px-2 -mx-3 -mb-2 rounded-b-lg">
                                        <button class="hover:text-gray-600 text-gray-500 font-bold py-1 px-2" onClick={() => { handleBuyClick(false, "-") }}>
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='max-w-7xl mx-auto pt-1 px-2'>
                <div className="grid items-center justify-center rounded-md bg-slate-800 p-2 mt-2">
                    <div className="flex items-center justify-center">
                        <p className="text-sm md:text-xl text-white pt-1 px-1 mb-1 font-medium tracking-wider " onClick={() => { changeStock() }}>
                            {props.details ? props.details.symbol : "NA"}
                        </p>
                    </div>

                    <div className="mx-2 mb-1 mt-1 flex space-x-4 bg-slate-600 px-4 py-1 rounded-2xl">
                        <span className={`text-sm ${(props.details && props.details.changePercentage > 0) ? "text-green-500" : "text-red-500"}`}>{(props.details) ? props.details.price : "NA"}</span>
                        <span className="text-sm text-white">{(props.details) ? props.details.change : "NA"} <span className={`text-sm ${(props.details && props.details.changePercentage > 0) ? "text-green-500" : "text-red-500"}`}>{(props.details) ? "(" + props.details.changePercentage + ")" : "NA"}</span></span>
                    </div>
                </div>

                <div className="mx-auto mt-1">
                    <LiveCandlestickChart />
                </div>






            </div>

        </div>
    )
}

const mapStateToProps = state => {
    return {
        details: state.stockDetails.details || {}
    }
}

const mapDispatchToProps = dispatch => {
    return {
        searchStock: param => dispatch(searchStock(param))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketRevamp);