import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select'
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { connect } from 'react-redux';
import { dataaa } from './data';

const LiveCandlestickChart = (props) => {
    const [data, setData] = useState([]);
    const [tooltipContent, setTooltipContent] = useState('');
    const [selectedIndicator, setSelectedIndicator] = useState('');
    const [activeRange, setActiveRange] = useState('');
    const [chartHeight, setChartHeight] = useState(window.innerHeight * 0.65);
    const chartRef = useRef(null);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: 'none',
            border: 'none',
            minHeight: '30px',
            height: '30px',
            boxShadow: state.isFocused ? null : null,
            fontSize: '0.8rem',
            textAlign: 'center'
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
        }),
        menuPortal: (provided, state) => ({
            ...provided,
            fontSize: '0.8rem'
        }),
    };

    const options = {
        // title: {
        //     text: 'Live Candlestick Chart with Optional Indicators and Volume'
        // },
        chart: {
            backgroundColor: {
                linearGradient: [0, 0, 500, 500],
                stops: [
                    [0, 'rgb(255, 255, 255)'],
                    [1, 'rgb(240, 240, 255)']
                ]
            },
            //height: (16 / 9 * 100) + '%' // 16:9 ratio
            height: chartHeight,
        },
        rangeSelector: {
            enabled: false
        },
        xAxis: {
            type: 'datetime',
            min: data.length ? data[0][0] : null,
            max: data.length ? data[data.length - 1][0] : null,
            crosshair: {
                width: 1,
                color: '#000000',
                dashStyle: 'Dash'
            }
        },
        yAxis: [{
            crosshair: {
                width: 1,
                color: '#000000',
                dashStyle: 'Dash',
                label: {
                    enabled: true,
                    // formatter: function () {
                    //     console.log(this);
                    //     return this.dataMax ? this.dataMax.toFixed(2) : 'N/A';
                    // }
                }
            }
        }],
        tooltip: {
            enabled: true,
            useHTML: true,
            formatter: function () {
                const point = this.points[0];
                if (point && point.point) {
                    const borderColor = point.point.close >= point.point.open ? 'green' : 'red';
                    setTooltipContent(`
                        <div style="display: flex; align-items: center; padding: 5px; background-color: #fff; border: 2px solid ${borderColor}; border-radius: 5px;">
                            <span style="margin-right: 10px;">Open: ${point.point.open?.toFixed(2) || 'N/A'}</span>
                            <span style="margin-right: 10px;">High: ${point.point.high?.toFixed(2) || 'N/A'}</span>
                            <span style="margin-right: 10px;">Low: ${point.point.low?.toFixed(2) || 'N/A'}</span>
                            <span>Close: ${point.point.close?.toFixed(2) || 'N/A'}</span>
                        </div>
                    `);
                }
                return false;
            },
            shared: true
        }
    };

    useEffect(() => {
        //setData(dataaa)
        setData(props.history)
        console.log('Data set:', props.history); // Log incoming data
    }, [props.history]);

    useEffect(() => {
        if (chartRef.current) {
            const chart = chartRef.current.chart;
            chart.series[0].setData(data, false);
            chart.redraw();
        }
        //activeRange && handleRangeSelect(activeRange)
    }, [data]);

    useEffect(() => {
        if (chartRef.current) {
            const chart = chartRef.current.chart;
            chart.series[0].setData(data, false);
            chart.redraw();

            // Update x-axis min and max based on data
            if (data.length) {
                const min = data[0][0];
                const max = data[data.length - 1][0];
                chart.xAxis[0].setExtremes(min, max);
            }
        }
    }, [data]);

    useEffect(() => {
        if (data.length > 0) {
            const lastPoint = data[data.length - 1];
            setTooltipContent(`
                <div style="display: flex; align-items: center; padding: 5px; background-color: #fff; border: 2px solid ${lastPoint[4] >= lastPoint[1] ? 'green' : 'red'}; border-radius: 5px;">
                    <strong style="margin-right: 10px;">Latest Update:</strong>
                    <span style="margin-right: 10px;">Open: ${lastPoint[1]?.toFixed(2) || 'N/A'}</span>
                    <span style="margin-right: 10px;">High: ${lastPoint[2]?.toFixed(2) || 'N/A'}</span>
                    <span style="margin-right: 10px;">Low: ${lastPoint[3]?.toFixed(2) || 'N/A'}</span>
                    <span>Close: ${lastPoint[4]?.toFixed(2) || 'N/A'}</span>
                </div>
            `);
        }
    }, [data]);

    useEffect(() => {
        const handleResize = () => {
          setChartHeight(window.innerHeight * 0.5); // Adjust height on resize
        };
    
        window.addEventListener('resize', handleResize);
        
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    const handleRangeSelect = (range) => {
        const chart = chartRef.current.chart;
        if (!data.length) return; // Exit if no data

        const min = data[0][0];
        const max = data[data.length - 1][0];
        const rangeInMillis = {
            '30m': 30 * 60 * 1000,
            '1hr': 60 * 60 * 1000,
            'all': max - min
        };

        setActiveRange(range);
        const rangeMax = range === 'all' ? max : Math.min(max, Date.now());
        const rangeMin = range === 'all' ? min : Math.max(min, rangeMax - rangeInMillis[range]);

        chart.xAxis[0].setExtremes(rangeMin, rangeMax);
    };


    const option = [
        { value: 'select', label: 'Select'},
        { value: 'MA', label: 'MA' },
        { value: 'EMA', label: 'EMA' },
        { value: 'BollingerBands', label: 'BB' }
    ]

    return (
        <div className=''>
            <div className="flex items-center justify-center rounded-md bg-slate-800 p-2" >
                <div class="flex text-white justify-between">
                    <span class='flex text-xs pl-1'>Open <span class='pl-1 text-yellow-300'>{props.details && props.details.open}</span></span>
                    <span class='flex text-xs pl-1'>High <span class='pl-1 text-yellow-300'>{props.details && props.details.high}</span></span>
                    <span class='flex text-xs pl-1'>Low <span class='pl-1 text-yellow-300'>{props.details && props.details.low}</span></span>
                    <span class='flex text-xs pl-1'>Close <span class='pl-1 text-yellow-300'>{props.details && props.details.close}</span></span>
                </div>
            </div>
            <div class='flex w-full md:w-1/2 mt-1'>
                <div class='flex w-1/2'>
                    <button class='h-7 md:h-10 w-32 pt-1 pb-1 text-black rounded-md text-xs' onClick={() => handleRangeSelect('30m')} style={buttonStyle(activeRange === '1m')}>
                        30m
                    </button>
                    <button class='h-7 md:h-10 w-32 pt-1 pb-1 text-black rounded-md text-xs' onClick={() => handleRangeSelect('1hr')} style={buttonStyle(activeRange === '5m')}>
                        1hr
                    </button>
                    <button class='h-7 md:h-10 w-32 pt-1 pb-1 text-black rounded-md text-xs' onClick={() => handleRangeSelect('all')} style={buttonStyle(activeRange === 'all')}>
                        All
                    </button>
                </div>
                <div class='w-full'>
                    <Select id="underline_select" styles={customStyles} onChange={(e) => setSelectedIndicator(e.value)} class="block py-2.5 px-0 w-1/3 text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer" options={option}>
                    </Select>
                </div>
            </div>
            <div  style={{ width: '100%', height: `${chartHeight}px` }}>
                <HighchartsReact
                    ref={chartRef}
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={{
                        ...options,
                        series: [
                            {
                                type: 'candlestick',
                                name: 'Price',
                                data: data,
                                color: 'red',
                                upColor: 'green',
                                lineColor: 'red',
                                upLineColor: 'green',
                                groupPadding: 0.7,
                                pointPadding: 0.7,
                                dataGrouping: {
                                    enabled: true,
                                    units: [['minute', [1]]] // Group data by day
                                }
                            },
                            ...renderIndicatorSeries(selectedIndicator, data)
                        ],
                    }}
                    updateArgs={[true, true, true]}
                />
            </div>
        </div>
    );
};

const buttonStyle = (isActive) => ({
    backgroundColor: isActive && '#007bff',
    color: isActive && '#ffffff'
});

// Indicator rendering functions
const renderIndicatorSeries = (selectedIndicator, data) => {
    let series = [];

    if (selectedIndicator === 'MA') {
        const maData = calculateMovingAverage(data, 5);
        series.push({
            type: 'line',
            name: '5-period MA',
            data: maData,
            color: 'red',
            tooltip: {
                pointFormat: 'MA: {point.y:.2f}'
            }
        });
    } else if (selectedIndicator === 'EMA') {
        const emaData = calculateEMA(data, 5);
        series.push({
            type: 'line',
            name: '5-period EMA',
            data: emaData,
            color: 'blue',
            tooltip: {
                pointFormat: 'EMA: {point.y:.2f}'
            }
        });
    } else if (selectedIndicator === 'BollingerBands') {
        const bbData = calculateBollingerBands(data, 5);
        const upperBand = bbData.map(point => [point[0], point[1]]);
        const lowerBand = bbData.map(point => [point[0], point[2]]);

        series.push({
            type: 'line',
            name: 'Upper Bollinger Band',
            data: upperBand,
            color: 'green',
            tooltip: {
                pointFormat: 'Upper Band: {point.y:.2f}'
            }
        });

        series.push({
            type: 'line',
            name: 'Lower Bollinger Band',
            data: lowerBand,
            color: 'orange',
            tooltip: {
                pointFormat: 'Lower Band: {point.y:.2f}'
            }
        });
    }

    return series;
};

// Calculation functions remain unchanged
const calculateMovingAverage = (data, period) => {
    const movingAverages = [];
    for (let i = period - 1; i < data.length; i++) {
        const sum = data.slice(i - period + 1, i + 1).reduce((acc, curr) => acc + curr[4], 0);
        const average = sum / period;
        movingAverages.push([data[i][0], average]);
    }
    return movingAverages;
};

const calculateEMA = (data, period) => {
    const ema = [];
    const k = 2 / (period + 1);
    let previousEma = data[0][4];

    data.forEach((point, index) => {
        if (index === 0) {
            ema.push([point[0], previousEma]);
        } else {
            const currentEma = (point[4] - previousEma) * k + previousEma;
            ema.push([point[0], currentEma]);
            previousEma = currentEma;
        }
    });
    return ema;
};

const calculateBollingerBands = (data, period) => {
    const bands = [];
    for (let i = period - 1; i < data.length; i++) {
        const slice = data.slice(i - period + 1, i + 1);
        const average = slice.reduce((acc, curr) => acc + curr[4], 0) / period;
        const stdDev = Math.sqrt(slice.reduce((acc, curr) => acc + Math.pow(curr[4] - average, 2), 0) / period);
        bands.push([
            data[i][0],
            average + 2 * stdDev,
            average - 2 * stdDev
        ]);
    }
    return bands;
};

const mapStateToProps = (state) => ({
    history: state.stockDetails.history || [],
    details: state.stockDetails.details || {}
});

export default connect(mapStateToProps, null)(LiveCandlestickChart);
