import React from 'react';
import CreateAccountForm from "./create-account";
import LoginForm from "./login"
import Twofa from "./two-factor-auth";
import { authVisibilty } from "../../actions/auth";
import { connect, useDispatch, useSelector } from "react-redux";
import Home from '../home';


const Auth = () => {
  const isLoginVisible = useSelector((state) => state.auth.isLoginVisible);
  const isRegisterVisible = useSelector((state) => state.auth.isRegisterVisible);
  const is2FAVisible = useSelector((state) => state.auth.is2FAVisible);

  return (
    <div className="grid h-screen grid-cols-2 md:grid-cols-2">
      <div className="hidden sm:block  bg-gray-100">
        <div className="h-screen p-20">
          <div className="flex w-3 flex-col justify-start items-start">
            <span className="bg-clip-text text-6xl text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
              CoreX
            </span>
          </div>
          <p className="py-2 break-words text-gray-400">Join CoreX and experience the future of trading education. Whether you aim to refine your skills or transition into real trading, CoreX provides the tools and support you need to succeed.</p>

          <div className="absolute bottom-0 left-0 p-4 sm:p-8 md:p-12 lg:p-20 flex flex-col">
          <span className="bg-clip-text text-4xl text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
          Get Started Today!
            </span>
            <span className="bg-clip-text p-2 text-gray-400">
              {" "}
              Trade with confidence
            </span>
          </div>
        </div>
      </div>

      <div className="col-span-2 sm:col-span-1">
        <div className="flex justify-center items-center md:px-4 px-6 w-full">
          {isLoginVisible ? <LoginForm /> : null}
          {isRegisterVisible ? <CreateAccountForm /> : null }
          {is2FAVisible ? <Twofa /> : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  console.log("state",state);
  return {
    auth: state.auth || {}
  }
}

const mapDispatchToProps = dispatch => {
  return {
    authVisibilty: param => dispatch(authVisibilty(param))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
