import React, { useEffect, useState } from "react";
import { Menu, X } from "lucide-react";
import logo from "../assets/logo.png";
import {navItems} from '../constants/index'
import { Link, redirect, useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { loadUserConfig } from "../actions/user";
import Cookies from "universal-cookie";
import { userAccountLoginSuccess } from "../actions/auth";

const Navbar =(props)=>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cookies = new Cookies();
    const [mdOpen, setMDOpen] = useState(false);

    const toggleNB = (value) =>{
        console.log(value)
        console.log("value click")
        if(!value){
            setMDOpen(value)
        }
        else
            setMDOpen(!mdOpen);
    }

    const handleAuthClick = () => {
        navigate("/auth");
        toggleNB(false);
    }

    useEffect(()=>{
        const cookie = new Cookies();
        if(cookie.get('coreX_access_token') && localStorage.getItem('coreX')){
            console.log("props ",props)
            dispatch(loadUserConfig(localStorage.getItem('coreX')));
            dispatch(userAccountLoginSuccess({
                type: "USER_LOGIN_SUCCESS",
                accessToken: {tokens: {access_token: cookie.get('coreX_access_token'), refresh_token: cookie.get('coreX_refresh_token')}},
                logIn: true
            }))
        }
    },[])

    return (
        <nav className="sticky top-0 z-50 py-3 backdrop-blur-lg border-b border-neutral-700/80 h-1/5">
            <div className="container px-4 mx-auto relative text-sm">
                <div className="flex justify-between items-center">
                    <div className="flex items-center flex-shrink-0">
                        {/* <img className="h-10 w-10 mr-2" src={logo} alt=""></img> */}
                        <Link to="/" className="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500 text-3xl">CoreX</Link>
                    </div>
                    <ul className="hidden lg:flex ml-14 space-x-12">
                        {navItems.map((item, index)=>
                            <li key={index}>
                                <Link to={item.href} >{item.label}</Link>
                            </li>
                        )}
                    </ul>
                    {!props.auth.logIn ? 
                    <div className="hidden lg:flex justify-center space-x-12 items-center">
                            <button className="custom-button" onClick={handleAuthClick}>Sign In</button>
                    </div> : ""}
                    <div className="lg:hidden md:flex flex-col justify-end">
                        <button onClick={toggleNB}>
                            {mdOpen ? <X/> : <Menu/>}
                        </button>
                    </div>
                </div>
                {mdOpen ? (
                    <div className="fixed right-0 z-20 text-white bg-gray-700 w-full p-12 flex flex-col justify-center items-center lg:hidden">
                        <ul>
                        {navItems.map((item, index)=>
                            <li key={index} className="py-2">
                                <Link to={item.href} onClick={toggleNB}>{item.label}</Link>
                            </li>
                        )}
                        </ul>
                        {!props.auth.logIn ?
                        <div className="flex space-x-6 mt-3">
                            <button className="h-10 p-2 text-nowrap border rounded-md" onClick={handleAuthClick}>
                                Sign In
                            </button>
                        </div> : "" }
                    </div>
                ) : ""}
            </div>
        </nav>
    )
}

const mapStateToProps = state => {
    console.log("Nav",state);
    return {
        auth: state.auth || {}
    }
}

const mapDispatchToProps = dispatch => {
    return {
       
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);