import React, { useContext, useEffect, useState } from "react";
import Navbar from "./Navbar";
import HeroSection from "./HeroSection";
import MarketSection from "./MarketSection";
import MarketPredict from "./MarketPredict";
import Footer from "./Footer";
import Leaderboard from "./LeaderBoard";

const Home = () => {
    return (
        <div className="min-h-screen max-w-screen-md m-auto">
           {/* <Navbar /> */}
            <div className='max-w-7xl mx-auto  px-6'>
                <div className="flex flex-wrap"><div className="w-full md:w-1/2"><HeroSection /></div><div className="w-full md:w-1/2"><Leaderboard /></div></div>
                <MarketSection />
                <MarketPredict />
                <Footer />
            </div>
        </div>
    )
}

export default Home;