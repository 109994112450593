import { authVisibilty } from "../../actions/auth";
import { connect, useDispatch, useSelector } from "react-redux";
import React, {useState} from 'react';
import { EventTypes }  from "../../constants/event-type";
import { useNavigate } from "react-router-dom";

const  Twofa = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch()

    const isLoginVisible = useSelector((state) => state.auth.isLoginVisible);
    const isRegisterVisible = useSelector((state) => state.auth.isRegisterVisible);
    const is2FAVisible = useSelector((state) => state.auth.is2FAVisible);

    const [otp, setOtp] = useState(new Array(6).fill(""));


  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    // Focus next input
    if (element.nextSibling && element.value !== "") {
      element.nextSibling.focus();
    }
  };

  const handleClick = () => {
    dispatch(authVisibilty(EventTypes.LOGIN, isLoginVisible));
    dispatch(authVisibilty(EventTypes.TWO_FACTOR_AUTH, true));
    dispatch(authVisibilty(EventTypes.REGISTER, true));
    navigate("/");
  }

  return (
    <div className="flex justify-center items-center">
      <div className="bg-white p-8 rounded-lg">
      <p className="text-gray-400 py-4 text-center">Please enter your code</p>
        <div className="flex space-x-4">
          {otp.map((data, index) => {
            return (
              <input
                className="w-12 h-12 custom-input text-center"
                type="text"
                name="otp"
                maxLength="1"
                key={index}
                value={data}
                onChange={(e) => handleChange(e.target, index)}
                onFocus={(e) => e.target.select()}
              />
            );
          })}
        </div>
        <button
          className="mt-6 w-full custom-button"
          onClick={handleClick}
        >
          Verify OTP
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
    console.log("state-login->>",state);
    return {
      details: state.stockDetails.details || {}
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      authVisibilty: param => dispatch(authVisibilty(param))
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(Twofa);
