const initailState = {
    logIn: false
};

const user = (state = initailState, action) => {
    switch (action.type) {
        case "LOAD_USER_DETAILS":
            return {
                ...state,
                user: action.user
            }
        default:
            return{
                ...state,
                logIn:false
            }
    }
}

export default user;