import React from "react";
import { topGainers } from "../constants";
import TopGainers from "./TopGainers";
import TopLosers from "./TopLosers";
import Indices from "./Indices";

const MarketSection = () => {
    return (
        <div className="w-full items-center my-2">
            <h1 className="text-2xl md:text-4xl lg:text-6xl flex flex-wrap justify-center items-center">Live Market Status</h1>
            <div className="container rounded-md m-auto mt-2 shadow-md">
                <div className="flex flex-wrap bg-slate-200 rounded-t-md">
                    <div className="w-full md:w-1/2 mt-1 p-2">
                        <TopGainers />
                    </div>
                    <div className="w-full md:w-1/2 mt-1 p-2">
                        <TopLosers />
                    </div>
                </div>
                <div className="p-2 bg-slate-200">
                    <h4 className="md:text-xl lg:text-2xl text-center">
                        Indices
                    </h4>
                    <Indices />
                </div>
            </div>
        </div>
    )
}

export default MarketSection;