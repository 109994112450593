import React from "react";
import { Link, redirect, useNavigate } from "react-router-dom";

const MarketPredict = () => {
    return(
        <div className="flex flex-col justify-center mr-auto items-center my-2 mt-5">
            <h1 className="text-2xl md:text-4xl lg:text-6xl flex flex-wrap justify-center items-center">Test Yourself</h1>
            <section className="container flex flex-col rounded-xl justify-center items-center mr-auto mt-2">
                <p className="mt-10 text-lg text-center text-neutral-500 max-w-4xl">
                    Scan the market and test your knowledge here to sharpen your confidence on your market
                    view. Just do analysis the stock price of today and get to know how close your analysis
                    at end of the day. Happy Analysis!!!
                </p>
                <button className="flex justify-center items-center my-10">
                    <Link to="/market" className="bg-gradient-to-r from-pink-500 to-violet-500 py-2 px-3 rounded-md text-white">Test Your Analysis</Link>
                </button>
            </section>
        </div>
    )
}

export default MarketPredict;