import { loadUser } from "../api/user";

const LOAD_USER_DETAILS = "LOAD_USER_DETAILS";

const loadUserConfigSuccess = (user) =>{
    return{
        type: LOAD_USER_DETAILS,
        user
    }
}

export const loadUserConfig = (user_id) => ( dispatch) =>{
    console.log("loadUserConfig");
    loadUser(user_id).then((res)=>{
        console.log("user res ",res);
        dispatch(loadUserConfigSuccess(res));
    }).catch((err)=>{
        console.log("User config failed ",err);
    })
}