import React, { useContext, useEffect, useState } from "react";
import Chart from "./Chart";
import { overViewItems, redItems, greenItems, normalItems, depthItems, redDepthItems, greenDepthItems, normalDepthItems, depthValueItems, stocks } from "../constants";
import { buySingleStock, defaultStockUpdate, searchStock, sellSingleStock, stockHistory, updateBSStatus } from "../actions/stock";
import { connect, useDispatch } from "react-redux";
import { stockList as stockIntial, defaultStock } from "../constants";
import LiveCandlestickChart from "./LiveCandlestickChart";
import 'react-notifications/lib/notifications.css';

const Market = (props) => {

    const [showSearch, setSearch] = useState(false);
    const [showBuySell, setBuySell] = useState(false);
    const [quantity, setQuantity] = useState(0)
    const [buySellAction, setBuySellAction] = useState(true);
    const [results, setResults] = useState([]);
    const [stock, setStock] = useState(props.defaultStock);
    const [stockList, setStockList] = useState(stockIntial);
    const [showStockError, setShowStockError] = useState(false);
    const [showStockHistoryError, setShowStockHistoryError] = useState(false);
    const [showBuySellError, setShowBuySellError] = useState(false);
    const [showBuySellProgress, setShowBuySellProgress] = useState(false);
    const [showPrediction, setShowPrediction] = useState(false);
    const dispatch = useDispatch()
    const changeStock = () => {
        setSearch(!showSearch);
    }

    const selectStock = (stock) => {
        setStock(stock);
        setSearch(false);
        //setResults(stockIntial)
        dispatch(searchStock(stock));
        dispatch(stockHistory(stock));
        dispatch(defaultStockUpdate(stock));
    }

    const handleSearch = (value) => {
        let input = value.target.value
        if (input && input.length > 2) {
            let st = stockList.filter((item) => item && item.value.includes(input));
            setStockList(st)
        } else if (input && input.length > 0) {
            setStockList(stockIntial)
        }
    }

    const handleBuyClick = (value, buy) => {
        setBuySell(value);
        setBuySellAction(buy);
        setShowBuySellError(false);
        dispatch(updateBSStatus(false))
    }

    const handlePredictClick = (value) => {
        setShowPrediction(value);
    }

    const handleBuySell = (value) => {
        if (props.user && props.user.user) {
            if (value)
                dispatch(buySingleStock(stock, quantity, props.user.user.id));
            else
                dispatch(sellSingleStock(stock, quantity, props.user.user.id));
        }
    }

    const clearSearch = () => {
        setSearch(false);
        setStockList(stockIntial)
    }

    // useEffect(() => {
    //     //dispatch(defaultStockUpdate(defaultStock));
    //     const fetchStockData = (stock) => {
    //         // dispatch(searchStock(stock));
    //         // dispatch(stockHistory(stock));
    //     };
    //     fetchStockData(props.stock);
    //     // const intervalId = setInterval(fetchStockData, 5000);
    //     // return () => clearInterval(intervalId);
    // }, []);

    useEffect(() => {
        //dispatch(defaultStockUpdate(defaultStock));
        dispatch(searchStock(props.defaultStock));
        dispatch(stockHistory(props.defaultStock));
    }, []);

    useEffect(() => {
        if (props.stockStatus === 'failed') {
            setShowStockError(true);
        } else {
            setShowStockError(false);
        }
    }, [props.stockStatus])

    useEffect(() => {
        if (props.stockHistoryStatus === 'failed') {
            setShowStockHistoryError(true);
        } else {
            setShowStockHistoryError(false);
        }
    }, [props.stockHistoryStatus])

    useEffect(() => {
        if (props.buySellStatus === 'failed') {
            setShowBuySellError(true);
        } else {
            setShowBuySellError(false);
            //handleBuyClick(false, "-");
        }
    }, [props.buySellStatus])

    return (
        <div className="max-w-screen-md m-auto">
            <div class={`fixed z-10 overflow-y-auto top-15 w-full left-0 ${showSearch ? "block" : "hidden"}`} id="modal">
                <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-gray-400 bg-opacity-50 h-screen">
                    <div class="w-full max-w-screen-xl mx-auto px-0 h-screen">
                        <div class="flex justify-center p-4 px-3 py-10">
                            <div class="w-full max-w-md">
                                <div class="bg-white shadow-md rounded-lg px-3 py-2 mb-4">
                                    <div class="block text-gray-700 text-lg font-semibold py-2 px-2">
                                        Stock List
                                    </div>
                                    <div class="flex items-center bg-gray-200 rounded-md">
                                        <input
                                            class="w-full rounded-md bg-gray-200 text-gray-400 text-sm leading-tight focus:outline-none py-2 px-2"
                                            id="search" type="text" placeholder="Search" onChange={handleSearch} />
                                    </div>

                                    <div class="py-3 text-sm">
                                        {
                                            stockList.map((item) =>
                                                <div class=" cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2 text-left" onClick={() => { selectStock(item.key) }}>
                                                    <span class="flex-grow font-medium px-2 hover:bg-blue-100" >{item.value}</span>
                                                </div>
                                            )
                                        }

                                    </div>
                                    <div class="block bg-gray-200 text-sm text-right py-2 px-3 -mx-3 -mb-2 rounded-b-lg">
                                        <button class="hover:text-gray-600 text-gray-500 font-bold py-2 px-4" onClick={() => { clearSearch() }}>
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class={`fixed z-10 overflow-y-auto top-15 w-full left-0 ${showBuySell ? "block" : "hidden"}`} id="modal">
                <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-gray-400 bg-opacity-50 h-screen">
                    <div class="w-full max-w-screen-xl mx-auto px-0 h-screen top-32">
                        <div class="flex justify-center p-4 px-3 py-10 top-32">
                            <div class="w-full max-w-md">
                                <div class="bg-white md:w-3/4 shadow-md rounded-lg px-3 py-2 mb-4">
                                    {!props.isBSAction ?
                                        <div>
                                            <div class="block text-gray-500 text-sm md:text-lg font-semibold py-2 px-2">
                                                Enter the quantity
                                            </div>
                                            <div class="mb-2 items-center  rounded-md">
                                                <input
                                                    class="w-full rounded-md bg-gray-200 text-gray-900 text-sm leading-tight focus:outline-none py-2 px-2"
                                                    id="search" type="text" placeholder="0" onChange={(e) => { setQuantity(e.target.value) }} />
                                                {buySellAction ? <button className=" shadow-md  mt-3 h-7 md:h-10 w-16 bg-green-600 text-white rounded-2xl text-sm md:text-base" onClick={(() => { handleBuySell(true,) })}>Buy</button> : <button className=" shadow-md  mt-3 h-7 md:h-10 w-16 bg-red-600 text-white rounded-2xl text-sm md:text-base" onClick={(() => { handleBuySell(false,) })}>Sell</button>}
                                            </div>
                                        </div>
                                        : <div className="min-h-20 flex justify-center items-center"> {props.buySellMessage}</div>}
                                    <div class="block bg-gray-100 text-sm text-right py-1 px-2 -mx-3 -mb-2 rounded-b-lg">
                                        <button class="hover:text-gray-600 text-gray-500 font-bold py-1 px-2" onClick={() => { handleBuyClick(false, "-") }}>
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class={`fixed z-10 overflow-y-auto top-15 w-full left-0 ${showPrediction ? "block" : "hidden"}`} id="modal">
                <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-gray-400 bg-opacity-50 h-screen">
                    <div class="w-full max-w-screen-xl mx-auto px-0 h-screen">
                        <div class="flex justify-center p-4 px-3 py-10">
                            <div class="w-full max-w-md">
                                <div class="bg-white md:w-3/4 shadow-md rounded-lg px-3 py-2 mb-4">
                                     <div className="min-h-20 flex justify-center items-center"> Hold on Prediction. Soon, You will get a chance to Shoot it.</div>
                                    <div class="block bg-gray-100 text-sm text-right py-1 px-2 -mx-3 -mb-2 rounded-b-lg">
                                        <button class="hover:text-gray-600 text-gray-500 font-bold py-1 px-2" onClick={() => { handlePredictClick(false) }}>
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='max-w-7xl mx-auto pt-1 px-2'>
                {props.isStockFetching ? <div class="flex items-center justify-center w-full absolute z-10 bg-gray-900 max-w-screen-md min-h-100vh opacity-50 text-white h-screen">Picking a stock for you... </div> : ""}
                {showStockError ? <div onClick={() => { changeStock() }}><span class='flex items-center justify-center text-xs md:text-lg text-red-600'>Stock Not available</span></div> :
                    <div className="grid items-center justify-center rounded-md bg-slate-800 p-2 mt-2">
                        <div className="flex items-center justify-center">
                            <p className="text-sm md:text-xl text-white pt-1 px-1 mb-1 font-medium tracking-wider " onClick={() => { changeStock() }}>
                                {props.details ? props.details.symbol : "NA"}
                            </p>
                        </div>

                        <div className="mx-2 mb-1 mt-1 flex space-x-4 bg-slate-600 px-4 py-1 rounded-2xl">
                            <span className={`text-sm ${(props.details && props.details.changePercentage > 0) ? "text-green-500" : "text-red-500"}`}>{(props.details) ? props.details.price : "NA"}</span>
                            <span className="text-sm text-white">{(props.details) ? props.details.change : "NA"} <span className={`text-sm ${(props.details && props.details.changePercentage > 0) ? "text-green-500" : "text-red-500"}`}>{(props.details) ? "(" + props.details.changePercentage + ")" : "NA"}</span></span>
                        </div>
                    </div>}
                <div className="mx-auto mt-2">
                    {showStockHistoryError ? <div><span class='flex items-center justify-center text-xs md:text-lg text-red-600'>Stock History Not available</span></div> :
                        <LiveCandlestickChart />}
                </div>
                <div class="text-center py-4 lg:px-4">
                    <div class="p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" >
                        <span class="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">New</span>
                        <span class="font-semibold mr-2 text-left flex-auto">Get Ready to win more by your virtual money</span>
                        <svg class="fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" /></svg>
                    </div>
                </div>
                {/* <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2 p-2">
                        <div className="mx-auto mt-2 bg-blue-100 rounded-md p-1">
                            <div className="mt-1 text-xl font-medium text-blue-900 pl-1"><p>Overview</p></div>
                            <div className="grid">
                                {overViewItems.map((item, index) =>
                                    <div className="flex justify-between px-2 mt-1"><p className="text-xs md:text-sm text-blue-950 font-normal">{item.label}</p>
                                        {redItems.includes(item.label) &&
                                            <p className="text-xs md:text-sm text-red-500">{(props.details && props.details[item.key]) ? props.details[item.key] : "NA"}</p>
                                        }
                                        {greenItems.includes(item.label) &&
                                            <p className="text-xs md:text-sm text-green-900">{(props.details && props.details[item.key]) ? props.details[item.key] : "NA"}</p>
                                        }
                                        {normalItems.includes(item.label) &&
                                            <p className="text-xs md:text-sm text-gray-800">{(props.details && props.details[item.key]) ? props.details[item.key] : "NA"}</p>
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 p-2">
                        <div className="w-full h-full mx-auto mt-1 bg-blue-200 rounded-md p-1">
                            <div className="mt-1 text-xl font-medium text-blue-900 pl-1"><p>Market Depth</p></div>
                            <div className="grid grid-cols-6 mx-auto mt-1 p-1">
                                {depthItems.map((item, index) =>
                                    <div className="flex px-0">
                                        {redDepthItems.includes(item) &&
                                            <p className="text-xs md:text-sm text-red-500">{item}</p>
                                        }
                                        {greenDepthItems.includes(item) &&
                                            <p className="text-xs md:text-sm text-green-900">{item}</p>
                                        }
                                        {normalDepthItems.includes(item) &&
                                            <p className="text-xs md:text-sm text-gray-800">{item}</p>
                                        }
                                    </div>
                                )}
                            </div>
                            <div className="grid grid-cols-6 pl-1">
                                {props.details && props.details.depth && props.details.depth.map((item, index) =>
                                    item.map((x, i) =>
                                        <div className="flex">
                                            <p className="text-xs md:text-sm">{x}</p>
                                        </div>
                                    )
                                )
                                }
                            </div>
                        </div>
                    </div>
                </div> */}
                {!localStorage.getItem('coreX') ? <div className="flex items-center justify-center space-x-3 m-auto mb-5 mt-5"><span>Please SignIn to start your trade journey.</span></div> :
                    <div className="flex items-center justify-center space-x-3 m-auto mb-5 mt-5">
                        <button className="h-10 w-32 bg-green-600 text-white rounded-lg text-xl" onClick={() => { handleBuyClick(true, true) }}>Buy</button>
                        <button className="h-10 w-32 bg-slate-700 text-white rounded-lg text-xl" onClick={()=>{handlePredictClick(true)}}>Predict</button>
                        <button className="h-10 w-32 bg-red-600 text-white rounded-lg text-xl" onClick={() => { handleBuyClick(true, false) }}>Sell</button>
                    </div>}

            </div>
            {/* <div className="sticky bottom-0 z-50 bg-gray-300 p-1 h-14 pt-2 flex justify-center space-x-5">
                <button className="h-10 w-32 bg-green-600 text-white rounded-md text-xl" onClick={() => { handleBuyClick(true, true) }}>Buy</button>
                <button className="h-10 w-32 bg-red-600 text-white rounded-md text-xl" onClick={() => { handleBuyClick(true, false) }}>Sell</button>
            </div> */}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        details: state.stockDetails.details || {},
        stockStatus: state.stockDetails.stockStatus || {},
        stockHistoryStatus: state.stockDetails.stockHistoryStatus || {},
        buySellStatus: state.stockDetails.buySellStatus || {},
        buySellError: state.stockDetails.buySellError || {},
        user: state.user.user || {},
        isBuying: state.stockDetails.isBuying || false,
        isBSAction: state.stockDetails.isBSAction || false,
        buySellMessage: state.stockDetails.buySellMessage || "",
        defaultStock: state.stockDetails.stock || defaultStock,
        isStockFetching: state.stockDetails.isStockFetching || false,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        searchStock: param => dispatch(searchStock(param)),
        stockHistory: param => dispatch(stockHistory(param)),
        buySingleStock: param => dispatch(buySingleStock(param)),
        sellSingleStock: param => dispatch(sellSingleStock(param))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Market);