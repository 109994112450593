import React from "react";
import { indices } from "../constants";

const Indices = () => {
    return (
        <div class="flex flex-wrap w-full overflow-x-hidden">
                <span className="flex flex-wrap md:flex-nowrap m-4 text-center mt-2 w-full">
                    {indices.map((item, index) =>
                        <div className="p-4 w-full">
                            <div className="border-2 border-red-700 px-3 py-4  xl:py-6 rounded-lg transform transition duration-500 hover:scale-110">

                                <h2 className="text-xs md:text-sm text-orange-700">{item.change} ({item.percentage}%)</h2>
                                <p className="flex items-center justify-center mr-auto md:text-sm lg:text-xl">{item.label}</p>
                                <p className="flex items-center justify-center mr-auto md:text-sm text-gray-900">{item.price}</p>
                            </div>
                        </div>
                    )}
                </span>
        </div>
    )
}

export default Indices;