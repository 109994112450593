import React from "react";
import { topGainers } from "../constants";

const TopGainers = () => {
    return (
        <div class="relative rounded-lg p-3">
            <label className="text-base md:text-xl tracking-wide pb-2 ">Top Gainers</label>
            <div class="relative overflow-y-auto rounded-lg h-80 mt-1">
                <table class="w-full text-sm text-left rtl:text-right text-blue-100 dark:text-blue-100">
                    <thead class="text-xs text-white uppercase bg-green-700 -600 dark:text-white">
                        <tr>
                            <th scope="col" class="px-3 py-2">
                                Symbol
                            </th>
                            <th scope="col" class="px-3 py-2">
                                Price
                            </th>
                            <th scope="col" class="px-3 py-2">
                                Change
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            topGainers.map((item, index) =>
                                <tr class={`${index % 2 == 0 ? "bg-green-200" : "bg-green-300"} text-slate-900 rounded-lg`}>
                                    <td class="px-3 py-2 text-xs md:text-base">
                                        {item.label}
                                    </td>
                                    <td class="px-3 py-2">
                                        {item.price}
                                    </td>
                                    <td class="px-3 py-2">
                                        {item.change}<br/>({item.percentage}%)
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TopGainers;